import styled from "styled-components";
import Review from "../components/Review";
import { ReviewWrapperWrapper, ReviewAuthor } from "./review.style";
import { Button, ButtonGroup } from "./button.style";

export const BannerWrapperWrapper = styled.div`
  background: ${(props) => props.theme.colors.yellow};
  width: 100vw;
  display: grid;
  place-items: center;
  @media (min-width: 1024px) {
    min-height: 100vh;
  }
`;

export const BannerWrapper = styled.div`
  margin-top: 4rem;
  background: ${(props) => props.theme.colors.yellow};
  display: grid;
  grid-gap: 1rem;
  grid-template-areas:
    "awards"
    "title"
    "image"
    "buttons"
    "review";
  @media only screen and (min-width: 768px) {
    margin-top: 6rem;
  }
  @media (min-width: 1024px) {
    padding: 0.5rem 1rem 0.5rem 3rem;
    align-items: start;
    grid-gap: 0;
    grid-template-rows: auto auto 1fr 1fr;
    grid-template-columns: 2fr 1fr 2fr;
    grid-template-areas:
      "title title image"
      "review review image"
      "awards awards image"
      "buttons buttons image";
  }
  @media (min-width: 1440px) {
    max-width: 80%;
  }
`;

export const BannerTitle = styled.div`
  grid-area: title;
  width: 100%;
  margin-bottom: 1.5rem;
  & h1 {
    text-align: center;
    font-family: Rubik, sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    color: #000000;
  }
  @media only screen and (min-width: 425px) {
    & h1 {
      font-size: 2rem;
    }
  }
  @media only screen and (min-width: 768px) {
    & h1 {
      font-size: 3rem;
    }
  }
  @media (min-width: 1024px) {
    & h1 {
      text-align: left;
      font-size: 2.5rem;
    }
  }
  @media (min-width: 1440px) {
    & h1 {
      font-size: 3rem;
    }
  }
  @media (min-width: 2000px) {
    & h1 {
      font-size: 4.5rem;
    }
  }
`;

export const BannerCoverImage = styled.div`
  grid-area: image;
  min-width: 100%;
  @media only screen and (min-width: 768px) {
    & .gatsby-image-wrapper {
      max-width: 80%;
      margin: 1rem auto 1rem auto;
    }
  }
  @media only screen and (min-width: 1024px) {
    & .gatsby-image-wrapper {
      max-width: 100%;
      margin: 1rem auto 1rem auto;
    }
  }
`;

export const BannerAwards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-area: awards;
  justify-items: center;
  padding: 0.25rem;
  margin-bottom: 1.5rem;
  width: 100%;
  height: 100%;
  grid-gap: 1rem;
  justify-self: center;
  align-self: center;
  & picture {
    display: grid;
    place-items: center;
  }
  & img {
    max-width: 100%;
  }
  @media (min-width: 1024px) {
    grid-gap: 5%;
  }
  @media (min-width: 1440px) {
    height: 100%;
  }
`;

export const BannerButtonGroup = styled(ButtonGroup)`
  display: flex;
  justify-content: center;
  grid-area: buttons;
  align-items: center;
  justify-self: center;
  width: max-content;
  margin-bottom: 1rem;
  @media only screen and (min-width: 768px) {
    margin-bottom: 2rem;
  }
  @media (min-width: 1024px) {
  }
`;

export const BannerReview = styled.div`
  display: flex;
  justify-content: center;
  justify-self: center;
  width: 100%;
  grid-area: review;
  margin-bottom: 1rem;
`;

export const StyledBannerReview = styled(Review)`
  height: 100%;
  background: transparent;
  padding: 0 1rem;
  /* margin: 2rem 0; */
  @media only screen and (min-width: 1024px) {
    margin: 0.5rem 0;
  }
  @media only screen and (min-width: 1440px) {
    margin: 1rem 0;
  }
  & ${ReviewWrapperWrapper} {
    background: transparent;
    border: none;
    max-width: 100%;
    padding: 1rem;

    & p {
      font-size: 1rem;
      text-align: center;
    }
    @media (min-width: 425px) {
      padding: 0;
      & p {
        font-size: 1.5rem;
      }
    }
    @media only screen and (min-width: 768px) {
      max-width: 80%;
      & p {
        font-size: 1.75rem;
      }
    }
    @media only screen and (min-width: 1024px) {
      min-width: 100%;
      & p {
        text-align: left;
        font-size: 1.5rem;
      }
      & ${ReviewAuthor} {
        text-align: right;
      }
    }
    @media only screen and (min-width: 1160px) {
      & p {
        font-size: 1.75rem;
        line-height: 2.5rem;
      }
    }
    @media only screen and (min-width: 1568px) {
      & p {
        font-size: 2rem;
        line-height: 2.75rem;
      }
    }
    @media only screen and (min-width: 2210px) {
      & p {
        font-size: 2.75rem;
        line-height: 3.5rem;
      }
    }
  }
`;
