import * as React from "react";
import { Button } from "../styles/button.style";
import { LastCallBtnGroup, LastCallList } from "../styles/lastCall.style";
import styled from "styled-components";
import { SectionBodyText } from "../styles/section.style";
import {
  SectionWrapper,
  SectionTitle,
  SectionImage,
} from "../styles/section.style";
import "typeface-rubik";
import "typeface-sacramento";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const LastCallWrapper = styled.section`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 1024px) {
    min-height: 100vh;
  }
`;

type LastCallProps = {};

const LastCall: React.FC<LastCallProps> = () => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const handleSetShowModal = React.useCallback(() => setShowModal(!showModal), [
    setShowModal,
    showModal,
  ]);
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "help.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFile(
        filter: {
          childMarkdownRemark: {
            frontmatter: { categories: { eq: "lastCall" } }
          }
        }
      ) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                title
                author
                quote
              }
              html
            }
          }
        }
      }
    }
  `);
  const { frontmatter } = data.allFile.edges[0].node.childMarkdownRemark;
  return (
    <>
      <LastCallWrapper id="help">
        <SectionWrapper reverse>
          <SectionTitle>{frontmatter.title}</SectionTitle>
          <SectionImage>
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="Зображення Євгенії як астронавтки, яка стоїть перед камерою з численними даними та статистикою, що передаються, немов з космічного корабля"
            />
          </SectionImage>
          <SectionBodyText>
            <span className="title">{frontmatter.title}</span> <br />
            <LastCallList>
              <li>
                <h3>Як купити книжку?</h3>
                Купити цю книжку насправді дуже просто! Лише натисни на червону
                кнопку, щоб перейти до оплати. Ця легка дія скерує тебе до
                електронної форми WayForPay, де ти зможеш здійснити оплату
                будь-якою банківською карткою, Apple Pay або Google Pay. Якщо ти
                бажаєш оплатити в інший спосіб, будь ласка, напиши мені з
                пропозицією альтернативного способу оплати на електронну
                пошту:&nbsp;
                <a className="mail" href="mailto:help@eugeniasenik.com">
                  help@eugeniasenik.com
                </a>
                .
                <LastCallBtnGroup>
                  <form action="https://eugeniasenik.wayforpay.shop/">
                    <Button type="submit">
                      <span>Купити книгу (200 ₴)</span>
                    </Button>
                  </form>
                </LastCallBtnGroup>
              </li>
              <li>
                <h3>Книжка була доставлена пошкодженою!</h3>
                Придбаний тобою екземпляр був пошкоджений під час доставки? Мені
                дуже прикро, тому я з радістю тобі допоможу! Ми обов’язково
                знайдемо вирішення, просто напиши мені за адресою:&nbsp;
                <a className="mail" href="mailto:help@eugeniasenik.com">
                  help@eugeniasenik.com
                </a>
                <br />
              </li>
              <li>
                <h3>Я не отримав(-ла) уривок</h3>
                <p>
                  Іноді може минути кілька хвилин чи навіть годин, перш ніж
                  уривок роману дістанеться твоєї електронної скриньки. Часом
                  він може випадково опинитися у розділі «Реклама» чи «Спам».
                  Будь ласка, перевір їхній вміст. Якщо і там листа від мене
                  немає, напиши мені за адресою: &nbsp;
                  <a className="mail" href="mailto:help@eugeniasenik.com">
                    help@eugeniasenik.com
                  </a>
                </p>
              </li>
              <li>
                <h3>Контакти</h3>
                <p>
                  З будь-яких питань ти можеш завжди написати мені за
                  адресою:&nbsp;
                  <a className="mail" href="mailto:info@eugeniasenik.com">
                    info@eugeniasenik.com
                  </a>
                </p>
              </li>
            </LastCallList>
          </SectionBodyText>
        </SectionWrapper>
      </LastCallWrapper>
    </>
  );
};

export default LastCall;
