import styled, { css } from "styled-components";

type SectionProps = {
  reverse?: boolean;
};

export const reverseSection = css`
  flex-direction: row-reverse;
`;

export const SectionWrapper = styled.section<SectionProps>`
  padding: 3.125rem 0.75rem;
  font-family: Rubik, "sans-serif" !important;
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    ${(props) => props.reverse && reverseSection};
  }
  @media (min-width: 1440px) {
    max-width: 80%;
  }
  @media (min-width: 2000px) {
    max-width: 60%;
  }
`;

export const SectionTitle = styled.h2`
  font-family: Rubik, "sans-serif";
  font-size: 2rem;
  color: #000000;
  margin-bottom: 3rem;
  text-align: center;
  grid-area: sectionTitle;
  @media only screen and (min-width: 425px) {
    font-size: 2.5rem;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const SectionBodyText = styled.div`
  font-family: Rubik, "sans-serif";
  font-size: 1rem;
  color: #000000;
  text-align: justify;
  line-height: 1.5rem;
  grid-area: sectionBody;
  /* background: lightskyblue; */
  & .title {
    display: none;
  }
  & .italic {
    font-style: italic;
  }
  & .author {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-style: italic;
  }
  & .mail {
    color: ${(props) => props.theme.colors.primary};
  }
  & a {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
  }
  & ul {
    list-style-type: none;
    & li {
      display: inline;
      padding: 0.25rem;
      & a {
        color: #000;
      }
    }
  }
  @media (min-width: 425px) {
    padding: 0 2rem;
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 300;
  }

  @media (min-width: 1024px) {
    flex: 1;
    & .title {
      font-size: 3rem;
      font-family: Rubik, "sans-serif";
      font-weight: 700;
      display: block;
    }
  }
  @media (min-width: 1440px) {
    & .title {
      font-size: 3rem;
    }
  }
  @media (min-width: 1600px) {
    & .title {
      font-size: 3.5rem;
    }
  }
`;

export const SectionImage = styled.div`
  margin-bottom: 1rem;
  margin: 0 auto;
  @media (min-width: 590px) {
    max-width: 50%;
  }
  @media (min-width: 1024px) {
    flex: 1;
    margin: 0 auto;

    & .gatsby-image-wrapper {
      max-width: 80%;
      margin: 0 auto;
    }
  }
  @media (min-width: 1660px) {
    max-width: 40%;
  }
`;
