import * as React from "react";

import {
  BannerWrapper,
  BannerTitle,
  BannerCoverImage,
  BannerAwards,
  BannerButtonGroup,
  BannerWrapperWrapper,
  StyledBannerReview,
  BannerReview,
} from "../styles/banner.style";
import { Button } from "../styles/button.style";
import "typeface-rubik";
import "typeface-sacramento";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Modal from "../components/Modal";
// import { useMixpanel } from "gatsby-plugin-mixpanel";

type BannerProps = {};

const Banner: React.FC<BannerProps> = () => {
  // const mixpanel = useMixpanel();
  // mixpanel.track("Banner Purchase");
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const handleSetShowModal = React.useCallback(() => setShowModal(!showModal), [
    setShowModal,
    showModal,
  ]);
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "front_cover_uk2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <BannerWrapperWrapper id="home">
        <BannerWrapper>
          <BannerAwards>
            <picture>
              <source srcSet="assets/goodreads_award1x.png 1x" />
              <img src="assets/goodreads_award2x.png"></img>
            </picture>
            <picture>
              <source srcSet="assets/pen_ukraine_award1x.png 1x" />
              <img src="assets/pen_ukraine_award2x.png"></img>
            </picture>
            <picture>
              <source srcSet="assets/booksxxi_award1x.png 1x" />
              <img src="assets/booksxxi_award2x.png"></img>
            </picture>
          </BannerAwards>
          <BannerTitle>
            <h1>
              Будинок із сірників,
              <br /> узятих із різних коробок
            </h1>
          </BannerTitle>
          <BannerCoverImage>
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="Книжка 'Будинок із сірників' у вертикальному положенні, на обкладинці зображене обличчя-колаж викладене із сірників."
            />
          </BannerCoverImage>
          <BannerReview>
            <StyledBannerReview
              author="Діана К.
"
              quote="Ця історія справді дає відповіді на питання, котрих ми деколи навіть не ставимо.
"
              profession=""
              background=""
            ></StyledBannerReview>
          </BannerReview>
          <BannerButtonGroup>
            <form action="https://eugeniasenik.wayforpay.shop/">
              <Button type="submit">
                <span>Купити книгу (200 ₴)</span>
              </Button>
            </form>
            <Button
              inverted
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              <span>Уривок</span>
            </Button>
          </BannerButtonGroup>
        </BannerWrapper>
      </BannerWrapperWrapper>
      {showModal && <Modal handleSetShowModal={() => handleSetShowModal()} />}
    </>
  );
};

export default Banner;
