import * as React from "react";
import styled from "styled-components";
import { FaEnvelope } from "react-icons/fa";

const FooterWrapper = styled.div`
  background: #000;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  color: #fff;
  & ul {
    list-style-type: none;
  }
  & li {
    margin: 1rem 0;
  }
  & a {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    & * {
      margin-right: 0.5rem;
    }
  }
`;
import "typeface-rubik";
import "typeface-sacramento";

type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  return (
    <FooterWrapper id="contact">
      <ul>
        <li>
          <a
            href="mailto:info@eugeniasenik.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaEnvelope /> Email
          </a>
        </li>
        <li>Євгенія Сенік © 2020</li>
      </ul>
    </FooterWrapper>
  );
};

export default Footer;
