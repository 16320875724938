import * as React from "react";
import Review from "../components/Review";
import {
  SectionTitle,
  SectionImage,
  SectionBodyText,
  SectionWrapper,
} from "../styles/section.style";
import "typeface-rubik";
import "typeface-sacramento";

import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

import Background from "../../static/images/bg_illustration_03.jpg";
import styled from "styled-components";

const StyledReview = styled(Review)`
  background-image: url(${Background});
  background-position: bottom;
`;

const AboutWrapper = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 1024px) {
    min-height: 100vh;
  }
`;

type AboutProps = {};

const About: React.FC<AboutProps> = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "back_cover.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFile(
        filter: {
          childMarkdownRemark: { frontmatter: { categories: { eq: "about" } } }
        }
      ) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                title
                author
                quote
              }
              html
            }
          }
        }
      }
    }
  `);

  const { frontmatter } = data.allFile.edges[0].node.childMarkdownRemark;

  return (
    <>
      <AboutWrapper id="about">
        <SectionWrapper>
          <SectionTitle>{frontmatter.title}</SectionTitle>
          <SectionImage>
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="Зворот книги 'Будинок із сірників', на якому написаний короткий опис роману."
            />
          </SectionImage>
          <SectionBodyText>
            <span className="title">{frontmatter.title}</span> <br />
            <div
              dangerouslySetInnerHTML={{
                __html: data.allFile.edges[0].node.childMarkdownRemark.html,
              }}
            />
          </SectionBodyText>
        </SectionWrapper>
      </AboutWrapper>
      <StyledReview
        author={frontmatter.author}
        quote={frontmatter.quote}
        stars={5}
        profession=""
        background=""
      ></StyledReview>
    </>
  );
};

export default About;
