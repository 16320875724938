import styled from "styled-components";
import { ButtonGroup } from "./button.style";

export const HeaderWrapper = styled.div`
  background: #fff;
  width: 100%;
  min-height: 3rem;
  position: fixed;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  z-index: 8000;
  & .hamburger {
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
  }
  @media (min-width: 1024px) {
    padding: 1rem;
    & .hamburger {
      display: none;
    }
  }
`;

export const HeaderLogo = styled.h1`
  font-family: Sacramento;
  font-size: 1.25rem;
  font-weight: 300;
  flex: 2;
  white-space: nowrap;
  margin-right: 1rem;
  @media only screen and (min-width: 425px) {
    font-size: 1.5rem;
  }
  @media (min-width: 1440px) {
    font-size: 2rem;
  }
`;

export const HeaderNav = styled.nav`
  display: none;
  @media (min-width: 1024px) {
      display: inline;
      & a {
        white-space: nowrap;
        padding: 0 0.6rem;
        text-decoration: none;
        font-size: 1rem;
        color: #000;
        font-family: Rubik, "sans-serif";
      }
    }
  }
  @media (min-width: 1440px) {
  }
`;

export const HeaderButtonGroup = styled(ButtonGroup)`
  display: none;
  @media only screen and (min-width: 1024px) {
    display: inline;
  }
`;
