import styled from "styled-components";

export const LastCallImage = styled.div`
  overflow: hidden;
  & picture {
    display: grid;
    place-items: center;
  }
  & img {
    align-self: center;
    max-width: 100%;
    min-height: 100%;
    @media (min-width: 1024px) {
      max-width: 40%;
    }
  }
`;

export const LastCallBtnGroup = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1rem;
  &::first-child {
    margin-right: 0.5rem;
  }
`;

export const LastCallList = styled.ul`
  & li {
    margin-bottom: 1rem;
    padding-bottom: 2rem;
  }
`;
