import * as React from "react";
import Review from "../components/Review";
import ReactTooltip from "react-tooltip";
import { SectionImage } from "../styles/section.style";
import {
  FaGoodreads,
  FaFacebook,
  FaYoutube,
  FaInstagram,
  FaBlog,
  FaLink,
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
  FaBlogger,
} from "react-icons/fa";
import {
  SectionTitle,
  SectionBodyText,
  SectionWrapper,
} from "../styles/section.style";
import styled from "styled-components";
import Background from "../../static/images/bg_illustration_01.jpg";
import "typeface-rubik";
import "typeface-sacramento";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

type AuthorProps = {};

const StyledReview = styled(Review)`
  background-image: url(${Background});
  background-position: top;
`;

const AuthorWrapper = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 1024px) {
    min-height: 100vh;
  }
`;

const Author: React.FC<AuthorProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "author_profile.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFile(
        filter: {
          childMarkdownRemark: { frontmatter: { categories: { eq: "author" } } }
        }
      ) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                title
                author
                quote
              }
              html
            }
          }
        }
      }
    }
  `);
  const { frontmatter } = data.allFile.edges[0].node.childMarkdownRemark;
  return (
    <>
      <AuthorWrapper id="author">
        <SectionWrapper>
          <SectionTitle>{frontmatter.title}</SectionTitle>
          <SectionImage>
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="Фрагмент ілюстрації з головною героїнею, яка вдягена у завелику на неї оранжеву робочу куртку."
            />
          </SectionImage>
          <SectionBodyText>
            <span className="title">{frontmatter.title}</span> <br />
            <div
              dangerouslySetInnerHTML={{
                __html: data.allFile.edges[0].node.childMarkdownRemark.html,
              }}
            />
            <br />
            <ul>
              <li>
                <a
                  href="http://eugeniasenik.in.ua"
                  target="_blank"
                  data-tip="Blog"
                  data-delay-show="500"
                >
                  <FaBlogger size="2rem" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/Senik.Eugenia"
                  data-tip="Facebook"
                  target="_blank"
                  data-delay-show="500"
                >
                  <FaFacebookSquare color="#3b5998" size="2rem" />
                </a>
              </li>
              <li>
                <a
                  href="http://www.instagram.com/eugeniasenik"
                  target="_blank"
                  data-tip="Instagram"
                  data-delay-show="500"
                >
                  <FaInstagramSquare color="#e1306c" size="2rem" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.goodreads.com/author/show/13994915._"
                  target="_blank"
                  data-tip="Goodreads"
                  data-delay-show="500"
                >
                  <FaGoodreads color="#53352D" size="2rem" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCbupfXxI_eeHYnVhYAaG_Pg"
                  target="_blank"
                  data-tip="YouTube"
                  data-delay-show="500"
                >
                  <FaYoutubeSquare color="#FF0000" size="2rem" />
                </a>
              </li>
            </ul>
          </SectionBodyText>
        </SectionWrapper>
      </AuthorWrapper>
      <StyledReview
        author="Мар'яна Максим'як"
        quote="Євгенія Сенік - перспективна молода письменниця, здатна писати цікаві та якісні романи довкола актуальних тем."
        stars={5}
        profession=""
        background=""
      ></StyledReview>
      <ReactTooltip effect="solid" offset={{ top: 10 }} />
    </>
  );
};

export default Author;
