import styled from "styled-components";
import { Button } from "./button.style";
import { motion } from "framer-motion";

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 90vh;
  width: 100vw;
  display: grid;
  place-items: center;
  z-index: 9000;
  @media only screen and (min-width: 1024px) {
    height: 100vh;
  }
`;

export const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9001;
  @supports (backdrop-filter: blur()) {
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.4);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
`;

export const ModalContainer = styled(motion.div)`
  background: #fff;
  border-radius: 0.25rem;
  z-index: 9002;
  margin: 1rem;
`;

export const ModalBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  & button {
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
  }
  border-bottom: 1px solid #d3d3d3;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 0 1rem 1rem 1rem;
  @media only screen and (min-width: 1024px) {
    align-items: center;
    grid-gap: 1rem;
  }
`;
export const ModalImage = styled.div`
  width: 60%;
  align-self: center;
  justify-self: center;
  margin-top: 1rem;
  @media only screen and (min-width: 1024px) {
    /* width: 100%; */
  }
`;

export const ModalThankYou = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-width: 25rem;
  & h1 {
    margin-bottom: 1rem;
    font-family: Sacramento;
    font-weight: 200;
    align-self: center;
    font-size: 3rem;
  }
  & p {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  & .hint {
    color: #808080;
  }
  & button {
    width: max-content;
    align-self: center;
    padding: 1rem 2rem;
    font-size: 1rem;
  }
`;

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem 1rem;
  & h1 {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    text-align: center;
  }
  & label {
    margin-bottom: 0.25rem;
    align-self: center;
  }
  & input {
    padding: 1rem;
    margin-bottom: 1.5rem;
    width: 100%;
    font-size: 1rem;
  }
  & ${Button} {
    width: 100%;
    align-self: center;
    padding: 1rem 2rem;
    font-size: 1rem;
  }
`;
