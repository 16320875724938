import styled from "styled-components";
import { motion } from "framer-motion";
import { Button } from "./button.style";

export const MenuWrapper = styled(motion.div)`
  width: 100%;
  padding: 0.75rem;
  height: min-content;
  background: #fff;
  position: fixed;
  z-index: 7000;
  top: 3.438rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 0;
  @media only screen and (min-width: 585px) {
    max-width: 25rem;
  }
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;

export const MenuNav = styled.nav`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  & a {
    min-width: 100%;
    background-color: #fff;
    color: ${(props) => props.theme.colors.primary};
    border: 1px solid ${(props) => props.theme.colors.primary};
    display: block;
    padding: 0.75rem;
    margin-bottom: 0.25rem;
    text-decoration: none;
    &:hover {
      background-color: ${(props) => props.theme.colors.primary};
      color: #fff;
    }
  }
`;

export const MenuOverlay = styled.div`
  height: 100vh;
  width: 100vh;
  z-index: 6999;
  position: fixed;
`;

export const MenuForm = styled.form`
  margin-top: 0.25rem;
  & ${Button} {
    width: 100%;
  }
`;
