import styled, { css } from "styled-components";

type ButtonProps = {
  inverted?: boolean;
  loading?: boolean;
};

export const Button = styled.button<ButtonProps>`
  border: none;
  display: inline-block;
  text-align: center;
  padding: 0.875rem 1.5rem;
  color: #fff;
  font-size: 0.75rem;
  cursor: pointer;
  border-radius: 1.5rem;
  background: ${({ theme }) => theme.colors.primary};
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  & span {
    font-family: Rubik, sans-serif;
  }
  @media only screen and (min-width: 375px) {
    font-size: 1rem;
  }
  ${(props) =>
    props.inverted &&
    css`
      background: #fff;
      border: 0.0625rem solid ${props.theme.colors.primary};
      color: ${props.theme.colors.primary};
    `};
  ${(props) =>
    props.loading &&
    css`
      background: grey;
      color: #fff;
    `};
`;

export const ButtonGroup = styled.div`
  /* background: lightgrey; */
  display: flex;
  & ${Button} {
    margin-left: 0.5rem;
  }

  @media (min-width: 1024px) {
    align-self: center;
    justify-content: space-around;
    grid-area: buttons;
    display: flex;
    & ${Button} {
      margin-left: 1rem;
    }
  }
`;
