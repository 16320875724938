import * as React from "react";
import { ReviewWrapperWrapper } from "../styles/review.style";
import {
  ReviewQuote,
  ReviewWrapper,
  ReviewAuthor,
  ReviewStars,
  ReviewStar,
} from "../styles/review.style";

type ReviewProps = {
  quote: string;
  author: string;
  stars?: number;
  profession: string;
  background: string;
  className?: string;
};
import "typeface-rubik";
import "typeface-sacramento";

const Review: React.FC<ReviewProps> = ({
  quote,
  author,
  stars,
  profession,
  background,
  className,
  ...props
}) => {
  return (
    <ReviewWrapper className={className}>
      <ReviewWrapperWrapper>
        <ReviewQuote>{quote}</ReviewQuote>
        <ReviewAuthor>
          {author}
          {profession && ", " + profession}
        </ReviewAuthor>
        <ReviewStars>
          {stars && (
            <>
              <ReviewStar />
              <ReviewStar />
              <ReviewStar />
              <ReviewStar />
              <ReviewStar />
            </>
          )}
        </ReviewStars>
      </ReviewWrapperWrapper>
    </ReviewWrapper>
  );
};

export default Review;
