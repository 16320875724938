import * as React from "react";
import { Helmet } from "react-helmet";
import { GlobalStyle } from "../themes/globalstyles";
import Header from "./Header";
import { defaultTheme } from "../themes/defaultTheme";
import styled, { ThemeProvider } from "styled-components";
import "typeface-rubik";
import "typeface-sacramento";

type DefaultLayoutProps = {};

const Main = styled.main`
  font-family: Rubik!important, "sans-serif";
`;

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  return (
    <>
      <Helmet
        title="The Matchstick House by Eugenia Senik"
        meta={[
          {
            name: "description",
            content:
              "A book by author Eugenia Senik called the Matchstick House",
          },
        ]}
      >
        <html lang="uk" />
        <meta
          property="og:title"
          content="Будинок із сірників, узятих із різних коробок"
        />
        <meta
          property="og:site_name"
          content="Будинок із сірників, узятих із різних коробок"
        />
        <meta property="og:url" content="https://shop.eugeniasenik.com" />
        <meta property="og:description" content="" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://budynok-iz-sirnykiv.netlify.app/images/book_pyramid.jpg"
        />
      </Helmet>
      <GlobalStyle />
      <ThemeProvider theme={defaultTheme}>
        <Header />
        <Main>{children}</Main>
      </ThemeProvider>
    </>
  );
};
