import styled from "styled-components";
import Star from "../../static/assets/star.svg";
import "typeface-rubik";
import "typeface-sacramento";

type ReviewProps = {
  background?: string;
};

export const ReviewWrapper = styled.div<ReviewProps>`
  padding: 1.5rem;
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.colors.yellow};
  height: 100vh;
  font-family: Rubik, "sans-serif";
  background-size: cover;
  @supports (-webkit-touch-callout: none) {
    background-attachment: scroll;
  }
  & p {
    max-width: 62.5rem;
    font-family: Rubik, "sans-serif";
    font-size: 1rem;
    font-style: italic;
    font-weight: 300;
    & span {
      font-family: Rubik, "sans-serif";
      color: #000000;
    }
  }
  @media only screen and (min-width: 768px) {
    p {
      font-size: 1.5rem;
      line-height: 2rem;
      font-family: Rubik, "sans-serif";
    }
  }
  @media (min-width: 1024px) {
    height: 100vh;
    background-size: "";
  }
`;

export const ReviewWrapperWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.yellow};
  border-radius: 0.25rem;
  padding: 1rem;
  border: 1px solid #000;
  @media (min-width: 1024px) {
    max-width: 50%;
  }
`;

export const ReviewQuote = styled.p`
  color: #000;
  margin-bottom: 0.75rem;
  &:before {
    content: '"';
  }
  &:after {
    content: '"';
  }
`;

export const ReviewAuthor = styled.p`
  text-align: right;
`;
export const ReviewProfession = styled.p``;
export const ReviewStars = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 6.25rem;
  float: right;
  @media (min-width: 1024px) {
    width: 6.25rem;
    text-align: right;
  }
`;

export const ReviewStar = styled(Star)``;
