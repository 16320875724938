import * as React from "react";

import "typeface-rubik";
import "typeface-sacramento";
import {
  MenuWrapper,
  MenuNav,
  MenuOverlay,
  MenuForm,
} from "../styles/menu.style";
import { Button } from "../styles/button.style";

type MenuProps = { handleSetShowMenu: () => void };
const Menu: React.FC<MenuProps> = ({ ...props }) => {
  return (
    <>
      <MenuWrapper
        transition={{
          type: "spring",
          stiffness: 250,
          damping: 80,
        }}
        initial={{ y: -300 }}
        animate={{ y: 0 }}
        exit={{ y: -300 }}
        key="menu"
      >
        <MenuNav>
          <a href="#home" onClick={() => props.handleSetShowMenu()}>
            Головна
          </a>
          <a href="#about" onClick={() => props.handleSetShowMenu()}>
            Про книжку
          </a>
          <a href="#illustrations" onClick={() => props.handleSetShowMenu()}>
            Ілюстрації
          </a>
          <a href="#author" onClick={() => props.handleSetShowMenu()}>
            Автор
          </a>
          <a href="#help" onClick={() => props.handleSetShowMenu()}>
            Підтримка
          </a>
          <a href="#contact" onClick={() => props.handleSetShowMenu()}>
            Контакти
          </a>
        </MenuNav>
        <MenuForm action="https://eugeniasenik.wayforpay.shop/">
          <Button type="submit">
            <span>Купити книгу (200 ₴)</span>
          </Button>
        </MenuForm>
      </MenuWrapper>
      <MenuOverlay onClick={() => props.handleSetShowMenu()} />
    </>
  );
};

export default Menu;
