import * as React from "react";
import Review from "../components/Review";
import { SectionImage } from "../styles/section.style";
import {
  SectionTitle,
  SectionBodyText,
  SectionWrapper,
} from "../styles/section.style";
import Background from "../../static/images/bg_illustration_02.jpg";
import styled from "styled-components";
import "typeface-rubik";
import "typeface-sacramento";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

type IllustrationsProps = {};

const StyledReview = styled(Review)`
  background-image: url(${Background});
`;

const IllustrationsWrapper = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 1024px) {
    min-height: 100vh;
  }
`;

const Illustrations: React.FC<IllustrationsProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "illustration2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFile(
        filter: {
          childMarkdownRemark: {
            frontmatter: { categories: { eq: "illustrations" } }
          }
        }
      ) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                title
                author
                quote
              }
              html
            }
          }
        }
      }
    }
  `);

  const { frontmatter } = data.allFile.edges[0].node.childMarkdownRemark;
  return (
    <>
      <IllustrationsWrapper id="illustrations">
        <SectionWrapper reverse>
          <SectionTitle>{frontmatter.title}</SectionTitle>
          <SectionImage>
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="Ілюстрація, на якій головна героїня летить у космосі, заповненому численними земними речами."
            />
          </SectionImage>
          <SectionBodyText>
            <span className="title">{frontmatter.title}</span> <br />
            <div
              dangerouslySetInnerHTML={{
                __html: data.allFile.edges[0].node.childMarkdownRemark.html,
              }}
            />
            <span className="author">- Сергій Костишин</span>
          </SectionBodyText>
        </SectionWrapper>
      </IllustrationsWrapper>
      <StyledReview
        author={frontmatter.author}
        quote={frontmatter.quote}
        stars={5}
        profession=""
        background=""
      ></StyledReview>
    </>
  );
};

export default Illustrations;
