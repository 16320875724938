import { createGlobalStyle } from "styled-components";
import "typeface-rubik";
import "typeface-sacramento";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Rubik, "sans-serif";
    scroll-behavior: smooth;
  }
  a.button {
  text-decoration: none;
  }
  button.button {
  border: none;
  background-color: transparent;
  font-family: inherit;
  padding: 0;
  cursor: pointer;

  @media screen and (-ms-high-contrast: active) {
    border: 2px solid currentcolor;
  }
}
`;
