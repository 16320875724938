import * as React from "react";
import { AnimatePresence } from "framer-motion";

import "typeface-rubik";
import "typeface-sacramento";
import {
  ModalWrapper,
  ModalContainer,
  ModalOverlay,
  ModalImage,
  ModalThankYou,
} from "../styles/modal.style";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { Button } from "../styles/button.style";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { FaTimes } from "react-icons/fa";
import { GiPresent } from "react-icons/gi";
import { ModalBar, ModalBody, ModalForm } from "../styles/modal.style";

type ModalProps = { handleSetShowModal: () => void };
const Modal: React.FC<ModalProps> = ({ ...props }) => {
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    addToMailchimp(email)
      .then((data) => {
        data.result === "success" && setLoading(false);
        data.result === "success" && setMessage(true);
      })

      .catch((error: Error) => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      });
  };

  console.log(email);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sample.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <ModalWrapper>
      {message ? (
        <ModalContainer
          transition={{
            type: "spring",
            stiffness: 250,
            damping: 17,
          }}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          key="success"
        >
          <ModalBar>
            <button onClick={() => props.handleSetShowModal()}>
              <FaTimes size="1rem" />
            </button>
          </ModalBar>
          <ModalBody>
            <ModalThankYou>
              <h1>Thank you!</h1>
              <p>Невдовзі ти отримаєш уривок роману на електронну пошту.</p>
              <p className="hint">
                Якщо він не надійде впродовж години, будь ласка, перевір спам та
                розділ реклами!
              </p>
              <Button inverted onClick={() => props.handleSetShowModal()}>
                Закрити
              </Button>
            </ModalThankYou>
          </ModalBody>
        </ModalContainer>
      ) : (
        <ModalContainer
          transition={{
            type: "spring",
            stiffness: 250,
            damping: 17,
          }}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          key="menu"
        >
          <ModalBar>
            <button onClick={() => props.handleSetShowModal()}>
              <FaTimes size="1rem" />
            </button>
          </ModalBar>
          <ModalBody>
            <ModalForm onSubmit={handleSubmit}>
              <h1>Ознайомитися з уривком роману</h1>
              <input
                placeholder="example@domain.com"
                name="email"
                type="email"
                required
                onChange={handleEmailChange}
              />
              <Button loading={loading} type="submit">
                {loading ? "зачекайте" : "Отримати уривок"}
              </Button>
            </ModalForm>
            <ModalImage>
              <Img
                fluid={data.file.childImageSharp.fluid}
                alt="Книжка 'Будинок із сірників' у вертикальному положенні, на обкладинці зображене обличчя-колаж викладене із сірників."
              />
            </ModalImage>
          </ModalBody>
        </ModalContainer>
      )}
      <ModalOverlay
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key="overlay"
        onClick={() => props.handleSetShowModal()}
      />
    </ModalWrapper>
  );
};

export default Modal;
