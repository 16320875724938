import * as React from "react";
import { DefaultLayout } from "../layouts/index";
import Banner from "../layouts/Banner";
import About from "../layouts/About";
import Illustrations from "../layouts/Illustrations";
import Author from "../layouts/Author";
import Footer from "../layouts/Footer";
import LastCall from "../layouts/LastCall";
import "typeface-rubik";
import "typeface-sacramento";

type BioComponentType = {};

const IndexPage: React.FC<BioComponentType> = ({ ...props }) => {
  return (
    <DefaultLayout>
      <Banner />
      <About />
      <Illustrations />
      <Author />
      <LastCall />
      <Footer />
    </DefaultLayout>
  );
};

export default IndexPage;
