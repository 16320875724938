import * as React from "react";
import {
  HeaderWrapper,
  HeaderNav,
  HeaderLogo,
  HeaderButtonGroup,
} from "../styles/header.style";
import { Button } from "../styles/button.style";
import "typeface-rubik";
import "typeface-sacramento";
import { FaBars } from "react-icons/fa";
import Menu from "../components/Menu";
type HeaderProps = {};

const Header: React.FC<HeaderProps> = () => {
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const handleSetShowMenu = React.useCallback(() => setShowMenu(!showMenu), [
    setShowMenu,
    showMenu,
  ]);
  return (
    <>
      <HeaderWrapper>
        <HeaderLogo>Eugenia Senik</HeaderLogo>
        <HeaderNav>
          <a href="#home">Головна</a>
          <a href="#about">Про книжку</a>
          <a href="#illustrations">Ілюстрації</a>
          <a href="#author">Автор</a>
          <a href="#help">Підтримка</a>
          <a href="#contact">Контакти</a>
        </HeaderNav>
        <HeaderButtonGroup>
          <form action="https://eugeniasenik.wayforpay.shop/">
            <Button type="submit">
              <span>Купити книгу (200 ₴)</span>
            </Button>
          </form>
        </HeaderButtonGroup>
        <button
          className="hamburger"
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        >
          <FaBars size="1rem" />
        </button>
      </HeaderWrapper>
      {showMenu && <Menu handleSetShowMenu={() => handleSetShowMenu()} />}
    </>
  );
};

export default Header;
